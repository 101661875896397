const r = {
    "0": {
        "1": {
            "1": {
                "selected": 1,
                "matched": 1,
                "coef": 3,
                "type": 0,
                "configId": 1
            }
        },
        "2": {
            "1": {
                "selected": 2,
                "matched": 1,
                "coef": 1,
                "type": 0,
                "configId": 1
            },
            "2": {
                "selected": 2,
                "matched": 2,
                "coef": 10,
                "type": 0,
                "configId": 1
            }
        },
        "3": {
            "2": {
                "selected": 3,
                "matched": 2,
                "coef": 2,
                "type": 0,
                "configId": 1
            },
            "3": {
                "selected": 3,
                "matched": 3,
                "coef": 45,
                "type": 0,
                "configId": 1
            }
        },
        "4": {
            "2": {
                "selected": 4,
                "matched": 2,
                "coef": 1,
                "type": 0,
                "configId": 1
            },
            "3": {
                "selected": 4,
                "matched": 3,
                "coef": 10,
                "type": 0,
                "configId": 1
            },
            "4": {
                "selected": 4,
                "matched": 4,
                "coef": 80,
                "type": 0,
                "configId": 1
            }
        },
        "5": {
            "2": {
                "selected": 5,
                "matched": 2,
                "coef": 1,
                "type": 0,
                "configId": 1
            },
            "3": {
                "selected": 5,
                "matched": 3,
                "coef": 3,
                "type": 0,
                "configId": 1
            },
            "4": {
                "selected": 5,
                "matched": 4,
                "coef": 20,
                "type": 0,
                "configId": 1
            },
            "5": {
                "selected": 5,
                "matched": 5,
                "coef": 150,
                "type": 0,
                "configId": 1
            }
        },
        "6": {
            "3": {
                "selected": 6,
                "matched": 3,
                "coef": 2,
                "type": 0,
                "configId": 1
            },
            "4": {
                "selected": 6,
                "matched": 4,
                "coef": 15,
                "type": 0,
                "configId": 1
            },
            "5": {
                "selected": 6,
                "matched": 5,
                "coef": 60,
                "type": 0,
                "configId": 1
            },
            "6": {
                "selected": 6,
                "matched": 6,
                "coef": 500,
                "type": 0,
                "configId": 1
            }
        },
        "7": {
            "0": {
                "selected": 7,
                "matched": 0,
                "coef": 1,
                "type": 0,
                "configId": 1
            },
            "3": {
                "selected": 7,
                "matched": 3,
                "coef": 2,
                "type": 0,
                "configId": 1
            },
            "4": {
                "selected": 7,
                "matched": 4,
                "coef": 4,
                "type": 0,
                "configId": 1
            },
            "5": {
                "selected": 7,
                "matched": 5,
                "coef": 20,
                "type": 0,
                "configId": 1
            },
            "6": {
                "selected": 7,
                "matched": 6,
                "coef": 80,
                "type": 0,
                "configId": 1
            },
            "7": {
                "selected": 7,
                "matched": 7,
                "coef": 1000,
                "type": 0,
                "configId": 1
            }
        },
        "8": {
            "0": {
                "selected": 8,
                "matched": 0,
                "coef": 1,
                "type": 0,
                "configId": 1
            },
            "4": {
                "selected": 8,
                "matched": 4,
                "coef": 5,
                "type": 0,
                "configId": 1
            },
            "5": {
                "selected": 8,
                "matched": 5,
                "coef": 15,
                "type": 0,
                "configId": 1
            },
            "6": {
                "selected": 8,
                "matched": 6,
                "coef": 50,
                "type": 0,
                "configId": 1
            },
            "7": {
                "selected": 8,
                "matched": 7,
                "coef": 200,
                "type": 0,
                "configId": 1
            },
            "8": {
                "selected": 8,
                "matched": 8,
                "coef": 2000,
                "type": 0,
                "configId": 1
            }
        },
        "9": {
            "0": {
                "selected": 9,
                "matched": 0,
                "coef": 2,
                "type": 0,
                "configId": 1
            },
            "4": {
                "selected": 9,
                "matched": 4,
                "coef": 2,
                "type": 0,
                "configId": 1
            },
            "5": {
                "selected": 9,
                "matched": 5,
                "coef": 10,
                "type": 0,
                "configId": 1
            },
            "6": {
                "selected": 9,
                "matched": 6,
                "coef": 25,
                "type": 0,
                "configId": 1
            },
            "7": {
                "selected": 9,
                "matched": 7,
                "coef": 125,
                "type": 0,
                "configId": 1
            },
            "8": {
                "selected": 9,
                "matched": 8,
                "coef": 1000,
                "type": 0,
                "configId": 1
            },
            "9": {
                "selected": 9,
                "matched": 9,
                "coef": 5000,
                "type": 0,
                "configId": 1
            }
        },
        "10": {
            "0": {
                "selected": 10,
                "matched": 0,
                "coef": 2,
                "type": 0,
                "configId": 1
            },
            "5": {
                "selected": 10,
                "matched": 5,
                "coef": 5,
                "type": 0,
                "configId": 1
            },
            "6": {
                "selected": 10,
                "matched": 6,
                "coef": 30,
                "type": 0,
                "configId": 1
            },
            "7": {
                "selected": 10,
                "matched": 7,
                "coef": 100,
                "type": 0,
                "configId": 1
            },
            "8": {
                "selected": 10,
                "matched": 8,
                "coef": 300,
                "type": 0,
                "configId": 1
            },
            "9": {
                "selected": 10,
                "matched": 9,
                "coef": 2000,
                "type": 0,
                "configId": 1
            },
            "10": {
                "selected": 10,
                "matched": 10,
                "coef": 10000,
                "type": 0,
                "configId": 1
            }
        }
    },
    "1": {
        "1": {
            "1": {
                "selected": 1,
                "matched": 1,
                "coef": 60,
                "type": 1,
                "configId": 1
            }
        },
        "2": {
            "1": {
                "selected": 2,
                "matched": 1,
                "coef": 30,
                "type": 1,
                "configId": 1
            }
        },
        "3": {
            "1": {
                "selected": 3,
                "matched": 1,
                "coef": 20,
                "type": 1,
                "configId": 1
            }
        },
        "4": {
            "1": {
                "selected": 4,
                "matched": 1,
                "coef": 15,
                "type": 1,
                "configId": 1
            }
        },
        "5": {
            "1": {
                "selected": 5,
                "matched": 1,
                "coef": 12,
                "type": 1,
                "configId": 1
            }
        },
        "6": {
            "1": {
                "selected": 6,
                "matched": 1,
                "coef": 10,
                "type": 1,
                "configId": 1
            }
        },
        "7": {
            "1": {
                "selected": 7,
                "matched": 1,
                "coef": 8,
                "type": 1,
                "configId": 1
            }
        },
        "8": {
            "1": {
                "selected": 8,
                "matched": 1,
                "coef": 7,
                "type": 1,
                "configId": 1
            }
        },
        "9": {
            "1": {
                "selected": 9,
                "matched": 1,
                "coef": 6,
                "type": 1,
                "configId": 1
            }
        },
        "10": {
            "1": {
                "selected": 10,
                "matched": 1,
                "coef": 5,
                "type": 1,
                "configId": 1
            }
        }
    }
}
export const calcBet=(tickets)=>{
    let bet = 0;
    Object.keys(tickets).map((key, index) => {
        bet += tickets[key]?.amount;
    })
    return bet
}

const compressArr=(rul,len)=>{
    let from='';
    let to=0;

    let newArray = []

    //console.log(rul)
    for(let i = 0; i <= len; i++) {
        if(rul[i] === undefined){
            if(from === ''){from=i}
            else{to = i}
        }else{
            if(from !== '' && i > 0){
                newArray.push({key:from+'-'+to,coef:0})
                newArray.push({key:i,coef:rul[i].coef})
                from='';
                to=0;
            }else{
                newArray.push({key:i,coef:rul[i].coef})
            }
        }
    }
    return newArray
}

export const drawWinGridByRules=(rules,tkt,results)=>{

    rules = rules.length <= 0? r: rules

    //console.log('drawWinGridByRules',rules,tkt,results)

    let balls =  tkt?.balls;
    let type =  tkt?.ticketType === 'classic'?0:1;
    let rul =  rules[type][balls.length];

    let content = ''

    let config = compressArr(rul,balls.length)

    //console.log('config',config)


    for(let i = 0; i < config.length; i++) {
        //console.log('config',config[i])
        let ballCompare = results.filter(num => balls.includes(num)).length;
        let range = typeof config[i]?.key;
        content += `<li class="${range === 'number'? (ballCompare === config[i]?.key?'active':''):(ballCompare >= config[i]?.key.split('-')[0] && ballCompare <= config[i]?.key.split('-')[1]?'active':'')}">
                        <span>${config[i]?.key}</span><span>${(tkt?.amount * config[i]?.coef).toFixed(2) }</span>
                    </li>`
        //if(rul[i] === undefined){
        //    content += `<li><span>${i}</span><span>0.00</span></li>`
        //}else{
        //    content += `<li><span>${i}</span><span>${(tkt?.amount * rul[i]?.coef).toFixed(2) }</span></li>`
        //}
    }

    /*balls.map((item,ind) => {
        let r =ind;

        if(rul[r] === undefined){
            content += `<li><span>${r}</span><span>0.00</span></li>`
        }else{
            content += `<li><span>${r}</span><span>${(tkt?.amount * rul[r]?.coef).toFixed(2) }</span></li>`
        }
        console.log(r,rul[r])
    })*/

    //console.log('drawWinGridByRules',rul,tkt)
    return content
}

export const coletTopResultsBall=(res)=>{
    let results = []

    res.map(item => {
        if(item?.status === "WIN"){
            results.push(item?.ball)
        }
    })

    return results
}


export const compareArrays = (arr1, arr2) => {
    const mergedArray = [...arr1, ...arr2];
    const uniqueArray = mergedArray.filter((item, index) => {
        return mergedArray.findIndex((obj) => {
            return JSON.stringify(obj) === JSON.stringify(item);
        }) === index;
    });
    return uniqueArray;
};
