import React, {useEffect, useState} from "react"

const Progress=({config})=>{
    const [prStyle, setPrStyle] = useState({
        transform:'translateX(0%)',
        backgroundColor:''
    });
    const [pr, setPr] = useState(0);
    const progressStyle=(tik)=>{
        let color = '#218d4c';
        if(tik?.gameState === 1){
            if(tik?.progress >= 50 && tik?.progress <= 75){
                color = 'rgb(222, 156, 56)'
            }else if(tik?.progress >= 75){
                color = 'rgb(222, 56, 116)'
            }else{
                color = '#218d4c';
            }
            setPrStyle({...prStyle,transform:`translateX(${tik?.progress * -1}%)`,backgroundColor:color})
        }else{
            setPrStyle({...prStyle,transform:`translateX(${(tik?.progress -100) * 1}%)`,backgroundColor:color})
        }
    }

    useEffect(()=>{
        const tm = setInterval(() => {
            let color = '#218d4c';
            if(config.gameState === 1){
                if(config?.progress >= 50 && config?.progress <= 75){
                    color = 'rgb(222, 156, 56)'
                }else if(config?.progress >= 75){
                    color = 'rgb(222, 56, 116)'
                }else{
                    color = '#218d4c';
                }
                setPrStyle({...prStyle,transform:`translateX(${pr}%)`,backgroundColor:color})
                if(pr > 0){setPr(0)}
                setPr(pr-1 < -100? -100 : pr-1)
            }else if(config.gameState === 2){
                setPrStyle({...prStyle,transform:`translateX(${pr}%)`,backgroundColor:color})
                if(pr !== -100){setPr(-100)}
                setPr( pr+1 >= 0? 0 : pr+1 )
            }

        }, (config.duration / 100) * (config.gameState === 1?1000:900));

        return () => {
            clearInterval(tm)
        };
    },[pr])

    useEffect(()=>{

        if(config.gameState === 1){
            setPr( 0)
            if(config.progress){
                setPr(-config.progress)
            }
        }else if(config.gameState === 2){
            setPr( -100)
            if(config.progress){
                setPr( (100-config.progress) * -1)
            }
        }

    },[config.gameState])

    return(
        <div className="progress_bar">
            <div className="progress_box">
                <div className="progress_line" data-state={config?.gameState} style={prStyle}></div>
            </div>
        </div>
    )
}
export default Progress
