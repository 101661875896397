import './myTickets.scss'
import TicketCard from "../ticketCard/TicketCard"
import React, {useEffect, useState} from "react"
import _ from "lodash"
import {calcBet, dravWinGridByRules, drawWinGridByRules} from "../../../core/utils/helper"
import {ticket} from "../../../assets/img/icon"

const MyTickets=({edition,myTktList,results,rules,onCopy})=>{
    const [betCount, setBetCount] = useState(0);
    const [tktCount, setTktCount] = useState(0);

    useEffect(()=>{
        setTktCount(_.size(myTktList))
        setBetCount(calcBet(myTktList))
    },[myTktList])

    return (
        <div id="my-tickets">
            <ul className="info">
                <li>
                    <p>Edition ID:</p>
                    <span>{edition}</span>
                </li>
                <li>
                    <p>Tickets</p>
                    <img src={ticket} alt="ticket"/> <span>{tktCount}</span>
                </li>
                <li>
                    <p>Total Bets, GEL</p>
                    <span>{betCount.toFixed(2)}</span>
                </li>
            </ul>
            <div className="list">
                {
                    Object.keys(myTktList).map((key, index) => {
                        //console.log(myTktList[key])
                        const tkt = myTktList[key];
                        //setBetCount(betCount+tkt?.amount)
                        return (
                            <div className="item" key={index}>
                                <div className="head">
                                    <span>{tkt?.ticketType}</span>
                                    <span>Bet GEL: <span>{tkt?.amount.toFixed(2)}</span></span>
                                </div>
                                <div className="bord">
                                    <ul className="bal_box">
                                        {
                                            tkt?.balls && tkt?.balls.map((itm, ind) => {
                                                return (
                                                    <li className={`${results.indexOf(itm) !== -1?'active':''}`} key={ind}>{itm}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <h4>Hit - Win (GEL)</h4>
                                    <ul className="grid" dangerouslySetInnerHTML={{ __html: drawWinGridByRules(rules,tkt,results) }}/>


                                </div>
                            </div>
                        )
                    })
                }

                {/*<div className="item">
                    <div className="head">
                        <span>Classic</span>
                        <span>Bet GEL: <span>0.20</span></span>
                    </div>
                    <div className="bord">
                        <ul className="bal_box">
                            <li>32</li>
                            <li>32</li>
                            <li  className="active">32</li>
                            <li>32</li>
                            <li>32</li>
                            <li>32</li>
                        </ul>
                        <h4>Hit - Win (GEL)</h4>
                        <ul className="grid">
                            <li>
                                <span>0</span>
                                <span>0.00</span>
                            </li>
                            <li  className="active">
                                <span>1</span>
                                <span>0.60</span>
                            </li>
                            <li>
                                <span>2</span>
                                <span>1.30</span>
                            </li>
                            <li>
                                <span>3</span>
                                <span>2.60</span>
                            </li>
                        </ul>
                    </div>
                </div>*/}
            </div>
        </div>
    )
}
export default MyTickets
