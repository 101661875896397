import React, {useRef, useEffect, memo} from "react"
import './assets/scss/style.scss';
import {useState} from "react";
import {InfoTab, ResultsTab, Ticket, History, Top, ConnectionLost, SvgDot, Progress, MyTickets, AutoPlayModal, Header, PayoutTable, JackpotModal} from "./components"
import {UseEvent} from "./core/hooks/useEvent"
import {autoPlayIcon, hot, ice} from "./assets/img/icon"
import _ from "lodash"



function KenoApp({socket,user}) {
    const ev = UseEvent();
    const countDown = useRef(0)
    const useOutsideAlerter=(ref)=> {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setBetBox(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const [config, setConfig] = useState({
        editionId : '',
        gameState : '',
        duration : '',
        progress : '',
        connection: false
    })

    const [loader, setLoader] = useState('');
    const balls = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80];
    const [sideTabs, setSideTabs] = useState(1);
    const [tab, setTab] = useState(0);
    const [bet, setBet] = useState('0.10');
    const [betBox, setBetBox] = useState(false);
    const [selNum, setSelNum] = useState([]);
    const [hotNums, setHotNums] = useState([]);
    const [iceNums, setIceNums] = useState([]);
    const [resultBalls, setResultBalls] = useState([]);
    const [ticketList, setTicketList] = useState({});
    const [myTktList, setMyTktList] = useState({});
    const [prevTickets, setPrevTickets] = useState([]);
    const [rules, setRules] = useState([]);
    const [history, setHistory] = useState({});
    const [top, setTop] = useState({});
    const [recoveryState, setRecoveryState] = useState(null);
    const [jackpot, setJackpot] = useState([]);
    const [jackpotModal, setJackpotModal] = useState(false);

    // autoPlay
    const [autoPlay,setAutoPlay] = useState({
        showModal:false,
        onOff:false,
        playCount:0,
        random:0
    })
    const [autoPlayOnOff, setAutoPlayOnOff] =useState(false)
    const [autoPlayModal, setAutoPlayModal] =useState(false)
    // End autoPlay


    useEffect(()=>{
        setSelNum([])
        if(config.gameState === 1){
            if(!config?.recovery){
                setTicketList({})
                setMyTktList({})
            }

            if(autoPlayOnOff){
                if(countDown.current > 0){
                    runAutoPlay()
                }else{
                    autoPlayAction({type:'reset',action:''})
                }

            }
        }
    },[config.gameState])

    useEffect(()=>{
        if(autoPlay.onOff){
            runAutoPlay()
        }
    },[autoPlay.onOff])

    useEffect(()=>{
        if(config.gameState === 2 && selNum.length === 20 && config.editionId === 34804){
            setJackpotModal(true)
        }
    },[selNum])


    useEffect(()=>{
        setTimeout(function(){
            let subscribe1=null;
            let subscribe2=null;
            let subscribe3=null;
            let subscribe4=null;
            let subscribe5=null;

            if(socket.connected){

                try {
                    subscribe1 = socket.subscribe('/exchange/keno-generator', e => {
                        try {
                            const tik =  JSON.parse(e.body)
                            //console.log("keno-generator", tik);

                            setRecoveryState(null)
                            setConfig({
                                ...config,
                                editionId:tik?.editionId,
                                gameState:tik?.gameState,
                                duration:tik?.duration,
                                progress:tik?.progress
                            })
                            setResultBalls(tik?.ballsToShow)
                            setHotNums(tik?.hot)
                            setIceNums(tik?.cold)
                            //progressStyle(tik)

                            //if(config?.recovery?.state){
                            //    let c = config;
                            //    c.filter((key,val)=> console.log(key,val))
                            //}

                        }catch (ex){
                            console.log('lash',ex)
                        }
                    })

                    subscribe2 = socket.subscribe('/exchange/keno-lobby', e => {
                        try {
                            const tik =  JSON.parse(e.body)
                            console.log("keno-lobby", tik);
                            lobbyAction(tik)


                        }catch (ex){
                            console.log('lash',ex)
                        }
                    })

                    subscribe3 = socket.subscribe('/exchange/keno.direct/'+user?.hash, e =>  {
                        try {
                            const tik =  JSON.parse(e.body)
                            console.log("keno.direct", tik);

                            directAction(tik)

                        }catch (ex){
                            console.log('lash',ex)
                        }
                    })

                    subscribe4 = socket.subscribe('/exchange/keno-generator-rules', e => {
                        try {
                            const tik =  JSON.parse(e.body)
                            console.log("rules", tik);
                            setRules(tik)

                            //directAction(tik)

                        }catch (ex){
                            console.log('lash',ex)
                        }
                    })

                    subscribe5 = socket.subscribe('/exchange/keno-jackpots', e => {
                        try {
                            const tik =  JSON.parse(e.body)
                            console.log("keno-jackpots", tik);
                            setJackpot(tik)
                        }catch (ex){
                            console.log('keno-jackpots-error',ex)
                        }
                    })

                    if(subscribe3){
                        getActions('state_recovery','state recovery')
                    }
                }catch (ex){
                    console.log('lash zur',ex)
                }
            }else{
                //console.log(1111111,socket.connected)
                //connectTimeout('lost')
            }

            return () =>{
                if(subscribe1){
                    subscribe1.unsubscribe()
                }
                if(subscribe2){
                    subscribe2.unsubscribe()
                }
                if(subscribe3){
                    subscribe3.unsubscribe()
                }
                if(subscribe4){
                    subscribe4.unsubscribe()
                }
                if(subscribe5){
                    subscribe5.unsubscribe()
                }
            }
        },2000)

    },[socket.connected])

    const runAutoPlay=()=>{
        //setSelNum([])

        if(config.gameState === 2){return}

        const n = new Set();
        let rand = autoPlay.random;
        if(autoPlay.random === 'random'){
            rand = Math.floor(Math.random() * 7) + 4;
        }

        while (n.size < rand) {
            const randomNumber = Math.floor(Math.random() * 80) + 1;
            n.add(randomNumber);
        }


        makeBet(Array.from(n))

        return
    }

    const autoPlayAction=(ob)=>{
        console.log('autoPlayAction',ob)
        let type = ob.type
        let action = ob.action
        switch (type){
            case 'reset':
                setAutoPlayModal(false)
                setAutoPlayOnOff(false)
                setAutoPlay({
                    showModal:false,
                    onOff:false,
                    playCount:0,
                    random:0
                })
                return;
            case 'playCount':
                countDown.current=action;
                setAutoPlay(prevState => ({
                    ...prevState,
                    playCount: action
                }));
                return;
            case 'random':
                setAutoPlay(prevState => ({
                    ...prevState,
                    random: action
                }));
                return;
            case 'startAutoPlay':
                setAutoPlayModal(false)
                setAutoPlayOnOff(true)

                //setAutoPlay(prevState => ({
                //    ...prevState,
                //    showModal: false,
                //    onOff: true
                //}));
                return;
            case 'countDown':

                countDown.current-=1;
                if(countDown.current >= 0) {
                    setAutoPlay(prevState => ({
                        ...prevState,
                        playCount:countDown.current
                    }));
                }
                return;
            case 'playBtnClick':
                if(autoPlayOnOff){
                    autoPlayAction({type:'reset'})
                }else{
                    setAutoPlayModal(!autoPlayModal)
                    //setAutoPlay(prevState => ({
                    //    ...prevState,
                    //    showModal: !prevState.showModal
                    //}));
                }

                return;
            default:return
        }
    }

    const lobbyAction=(tik)=>{
        switch (tik?.action){
            case "PLACE_BET":

                    if(tik?.data?.user?.hash !== user?.hash){
                        setTicketList((ticketList) => {
                            return { ...ticketList, [tik?.data?.requestId]: tik?.data };
                        });
                    }
                return
            case "GENERATOR_RULES":
                return
            default: return
        }
    }
    const directAction=(tik)=>{
        switch (tik?.action){
            case 'PLACE_BET':
                setLoader('')
                if(tik?.resultCode === 0){
                    setSelNum([])
                    setMyTktList((myTktList) => {
                        return { ...myTktList, [tik?.data?.requestId]: tik?.data };
                    });

                    if(countDown.current === 0){
                        autoPlayAction({type:'reset',action:''})
                    }else{
                        autoPlayAction({type:'countDown',action:''})
                    }


                    ev.emit('notify', {
                        show:true,
                        text: 'Your Bet Placed Successfully',
                        type: 'Success',
                        title: '',
                        delay: 2000,
                        close: true
                    })
                }else{
                    ev.emit('notify', {
                        show:true,
                        text: tik?.message.replace('[amount]',bet),
                        type:'Warning',
                        title: '',
                        delay: 3000,
                        close: true
                    })
                }
                return
            case 'TOP_COEF':

                break;
            case "HISTORY_EDITION":
            case "HISTORY_STATS":
            case "HISTORY_MY":
                setHistory(tik)
                break;
            case "TOP_COEF_DAY":
            case "TOP_COEF_MONTH":
            case "TOP_COEF_YEAR":
                setTop(tik)
                break;
            case "HISTORY_PREVIOUS_EDITION":
                setPrevTickets(tik?.data)
                break;
            case "STATE_RECOVERY":
                console.log('STATE_RECOVERY',tik?.data)

                let tkt_l = tik?.data?.tickets;
                let st = tik?.data?.state;

                setRules(tik?.data?.rules)
                setResultBalls(st?.ballsToShow)

                //setTicketList

                if(tkt_l && tkt_l?.length > 0){
                    let t_list = ticketList;
                    let myTkt = myTktList;
                    tik?.data?.tickets.map(item => {
                        if(item?.user?.hash === user?.hash){
                            myTkt[item?.id] = item
                        }else{
                            t_list[item?.id] = item
                        }
                    })
                    setTicketList(t_list)
                    setMyTktList(myTkt)
                }

                if(st){
                    setRecoveryState(st)
                    setConfig({
                        ...config,
                        editionId: st?.editionId,
                        duration: st?.duration,
                        progress: st?.progress,
                        gameState: st?.gameState,
                        recovery: {
                            state: 'recovery',
                            resultBalls:st?.ballsToShow
                        },
                    })
                }

                break;
            default:
                return
        }
    }


    const activeBetBox =()=> {
        setBetBox(true)
    }
    const betAction =(method)=> {
        let b = parseFloat(bet)
        if(method === 'minus'){
            b -= 0.1
        }else if(method === 'plus'){
            b += 0.1
        }

        if(b < 0.1){ b = 0.1}
        b= b.toFixed(2)
        setBet(b)
    }
    const randomBet =(action)=> {
        if(config?.gameState === 2){return} // გაცემის დროს არ მოხდეს ბურთის მონიშვნა

        let rand = action;
        const numbers = new Set();

        if(action === 'random'){
            rand =Math.floor(Math.random() * 7) + 4;
        }

        while (numbers.size < rand) {
            const randomNumber = Math.floor(Math.random() * 80) + 1;
            numbers.add(randomNumber);
        }

        setSelNum(Array.from(numbers));
    }
    const selectNumber =(action)=> {
        if(config?.gameState === 2){return} // გაცემის დროს არ მოხდეს ბურთის მონიშვნა
        if (!selNum.includes(action)) {
            if (selNum.length === 10) {
                return
            }
            setSelNum([...selNum, action]);
        } else {
            setSelNum(selNum.filter((n) => n !== action));
        }
    }

    const renderAside =(action)=> {
        switch (sideTabs){
            case 1:
                return <Ticket ticketList={ticketList} prevTickets={prevTickets}
                    onCopy={(e)=>config?.gameState === 1? setSelNum(e):''}
                    results={config?.gameState === 2? selNum : []}
                    getAction={(type,method)=>getActions(type,method)}
                />
            case 2:
                return <History data={history} getAction={(type,method)=>getActions(type,method)}/>
            case 3:
                return <Top data={top} getAction={(type,method)=>getActions(type,method)}/>
            default:
                return <Ticket/>
        }
    }

    const getActions=(type,method)=>{

        switch(type){
            case 'top':
                socket.send('/app/top/coef',method)
                break;
            case 'history':
                socket.send('/app/history',method)
                break;
            case 'previous':
                socket.send('/app/history',method)
                break;
            case 'state_recovery':
                socket.send('/app/state',method)
                break;
            default:
                break;
        }
    }


    // ფსონის განთავსება
    const makeBet =(n='')=> {
        if(n === '' && selNum.length === 0){return}
        setLoader('bet');
        let betObj = {
            "requestId": Date.now(),
            "editionId": config?.editionId,
            "balls": n!==''? n:selNum,
            "amount": bet,
            "ticketType":  tab === 0? 'classic':'last-ball'
        }
        console.log('bet',betObj)
        socket.send('/app/bet',betObj)
    }

    return (
        <div className="App">
            <header className="App-header">
                <Header jackpot={jackpot}/>
            </header>
            <div className="wrapper">

                <div className="call-main">
                    <div className={`info ${config?.gameState === 2? 'results':''}`}>

                        {
                            config?.gameState === 2?
                                <ResultsTab
                                    balls={resultBalls}
                                    onResult={(e)=>setSelNum(e)}
                                    recovery={recoveryState}
                                />
                                :
                                <InfoTab
                                    editionId={config?.editionId}
                                    i={iceNums}
                                    h={hotNums}
                                    onSelect={(num)=>selectNumber(num)}
                                />
                        }
                        <Progress config={config}/>
                    </div>
                    <div className="tabs">
                        <ul>
                            <li onClick={()=> setTab(0)} className={tab===0?'active':''}>Classic</li>
                            <li onClick={()=> setTab(1)} className={tab===1?'active':''}>Last Ball</li>
                        </ul>
                        <div className="tour_id">
                            <span>Edition ID:</span>
                            {config?.editionId? <span>{config?.editionId}</span>: <div className="tour_loader"><SvgDot fill='#6cb0bb'/></div>}
                        </div>
                    </div>
                    <div className="main-cont">
                        <div className="ball-box">
                            <div className="center-container">
                                <div className="box">
                                    <div className="balls">
                                        {
                                            balls.map((item, index) => {
                                                let num = index + 1;
                                                return (

                                                    num > 0 && num <= 80 && <div key={index} className={`ball ${'b_'+(num)} ${(num) <= 40?'hot':'ice'} ${selNum.includes(num)?'active':''}`} onClick={()=> selectNumber(num)}>
                                                            <div className="b">{num?num:''}</div>
                                                            {hotNums.includes(num) && <i><img src={hot} alt="hot"/></i>}
                                                            {iceNums.includes(num) && <i><img src={ice} alt="ice"/></i>}
                                                        </div>


                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bet-calc">
                            <div className="random-bet-box">
                                <i className="cross-arrow-icon"/>
                                <button onClick={()=>randomBet(4)}>4</button>
                                <button onClick={()=>randomBet(5)}>5</button>
                                <button onClick={()=>randomBet(6)}>6</button>
                                <button onClick={()=>randomBet(7)}>7</button>
                                <button onClick={()=>randomBet(8)}>8</button>
                                <button onClick={()=>randomBet(9)}>9</button>
                                <button onClick={()=>randomBet(10)}>10</button>
                                <button onClick={()=>randomBet('random')}><i className="triangle-icon"/></button>
                            </div>
                            <button className="clearBets" onClick={()=> config?.gameState === 1? setSelNum([]):''}>
                                <i className="icon"/>
                                <span>Clear</span>
                            </button>
                        </div>
                    </div>
                    <div className="bet-box">
                        <div className={`bet-action ${betBox? 'active':''}`} ref={wrapperRef} onClick={()=> activeBetBox()}>
                            <i className="icon min" onClick={()=> betAction('minus')}>&minus;</i>
                            <input className="" type="text" inputMode="decimal" name="bet" autoComplete="off" value={bet} onChange={(e)=> setBet(e.target.value)}/>
                            <i className="icon plus" onClick={()=> betAction('plus')}>&#43;</i>
                            <div className="hot-bet">
                                <button onClick={()=>setBet('1.00')}>1</button>
                                <button onClick={()=>setBet('5.00')}>5</button>
                                <button onClick={()=>setBet('10.00')}>10</button>
                                <button onClick={()=>setBet('15.00')}>15</button>
                                <button onClick={()=>setBet('20.00')}>20</button>
                                <button onClick={()=>setBet('30.00')}>30</button>
                                <button onClick={()=>setBet('50.00')}>50</button>
                            </div>
                        </div>
                        <button className="bet-btn" onClick={()=> loader !== 'bet'?makeBet():''} disabled={config?.gameState===2?true:false}>
                            Place bet
                            {loader === 'bet'? <div className="loader"><SvgDot/></div> : ''}

                        </button>
                        <div className="autoplay">
                            <button data-autoplay={autoPlayOnOff} onClick={()=> autoPlayAction({type:'playBtnClick'})}>
                                {
                                    autoPlayOnOff ? <>
                                        <span>{autoPlay.playCount}</span>
                                        </>:
                                        <img src={autoPlayIcon} alt="autoPlay"/>
                                }
                            </button>
                            {
                                autoPlayModal && <AutoPlayModal autoPlay={autoPlay} autoPlayAction={(e)=>autoPlayAction(e)} />
                            }
                        </div>
                    </div>
                </div>

                <aside>
                    {
                        /*<MyTickets rules={rules} edition={config?.editionId} onCopy={(e)=>config?.gameState === 1? setSelNum(e):''} myTktList={myTktList} results={config?.gameState === 2? selNum : []}/>*/

                        _.size(myTktList) > 0 && (
                            <MyTickets rules={rules} edition={config?.editionId} onCopy={(e)=>config?.gameState === 1? setSelNum(e):''} myTktList={myTktList} results={config?.gameState === 2? selNum : []}/>
                        )
                    }

                    <ul className="side_tabs">
                        <li onClick={()=>setSideTabs(1)} className={`${sideTabs===1?'active':''}`}>All bets</li>
                        <li onClick={()=>setSideTabs(2)} className={`${sideTabs===2?'active':''}`}>History</li>
                        <li onClick={()=>setSideTabs(3)} className={`${sideTabs===3?'active':''}`}>Top</li>
                    </ul>

                    <div className="aside-content" data-aside={sideTabs}>
                        {sideTabs && renderAside()}
                    </div>
                </aside>

                {
                     jackpotModal && <div className="keno-modal">
                        {/*<PayoutTable rules={rules} />*/}
                        <JackpotModal jackpot={jackpot}  onClose={(e)=>setJackpotModal(e)}/>
                    </div>
                }
                {/*{!socket.connected && <ConnectionLost/>}*/}

            </div>
        </div>
    );
}

export default KenoApp;
