import React, {useEffect, useMemo, useState} from "react"
import './History.scss'
import TicketCard from "../ticketCard/TicketCard"
import {arrow, sort} from "../../../assets/img/icon"
import _ from "lodash"
import {coletTopResultsBall, compareArrays} from "../../../core/utils/helper"

const History=({data,getAction,onCopy})=>{
    const title = {
        'my':'HAND HISTORY',
        'edition':'EDITION HISTORY',
        'stats':'BALL STATISTICS'
    }
    const [myTicketData,setMyTicketData]= useState({
        extra:null,
        data:[]
    })
    const [hisTab,setHisTab] = useState('')
    const [sortable,setSortable] = useState(false)

    useEffect(()=>{
        setHisTab('my')

        return ()=> {
            setHisTab('')
            setMyTicketData({...myTicketData,extra: null,data:[]})
        }
    },[])

    const stats = useMemo(()=>{

        if(data?.action?.indexOf(hisTab.toUpperCase()) === -1){
            return {}
        }

        if(data?.action === "HISTORY_STATS"){
            const maxNum = data?.data.reduce((acc, curr) => {
                return curr.count > acc ? curr.count : acc;
            }, 0);

            if(data?.action?.indexOf(hisTab.toUpperCase()) !== -1){
                return {maxNum:maxNum,data:data?.data}
            }

        }else if(data?.action === "HISTORY_EDITION"){
            let newa = []
            data?.data.map((item,ind)=>{
                Object.keys(item).map((key, index) => {
                    newa.push({id:key,data:item[key]})
                })
            })
            if(data?.action?.indexOf(hisTab.toUpperCase()) !== -1){
                return {data:newa}
            }

        }else if(data?.action === "HISTORY_MY"){
            if(data?.action?.indexOf(hisTab.toUpperCase()) !== -1) {
                //setMyTicketData({...myTicketData,data:data?.data})
                //let nd = [
                //    ...myTicketData.data
                //];
                //data?.data.forEach((element) => {
                //    if (!nd.includes(element)) {
                //        nd.push(element);
                //    }
                //});

                let nd = compareArrays(myTicketData.data,data?.data)
                nd = _.sortedUniq(nd);

                setMyTicketData({...myTicketData,data:nd})

                console.log('nd',nd)
                return {data:nd}
            }
        }

    },[data,hisTab])

    useEffect(()=>{
        if(hisTab !== ''){
            getAction('history',{action:hisTab,extra:myTicketData.extra})
        }
    },[hisTab,myTicketData.extra])

    const changeTab=(tab)=>{
        if(hisTab !== tab){
            setHisTab(tab)
        }
    }
    const calcPercent=(num)=>{
        return  100 / stats?.maxNum * num
    }
    const loaderContent=()=> {
        return <div className="history-loader">
            <div className="loader">
                <div className="inner one"/>
                <div className="inner two"/>
                <div className="inner three"/>
            </div>
        </div>
    }
    const drawEditionBalls=(ball)=> {
        return ball.map((n,i) => <li key={i}><div className={`bal ${(i===19? (n <= 40?'hot':'ice'):'') }`}>{n}</div></li>)
    }
    const getMoreTicket=(id)=> {
        setMyTicketData({...myTicketData,extra: id})
    }
    const drawHistoryContent=()=>{
        //console.log(stats[hisTab],hisTab)

        if(_.size(stats?.data) < 1){
            return loaderContent()
        }

        switch (data?.action){
            case "HISTORY_STATS":
                return <div className="history-stats">
                    <ul>
                    {
                        stats?.data?.map((item,ind) => {
                            return <li key={ind}>
                                <div className="stat_ball">{item?.ball}</div>
                                <div className="stat_line"><i style={{width: calcPercent(item?.count)+'%' }}/></div>
                                <span className="stat_count">{item?.count}</span>
                            </li>
                        })
                    }
                    </ul>
                </div>
            case "HISTORY_MY":
                return <div className="history-my">
                    {
                        stats?.data?.map((item,index) => {
                            return (
                                <TicketCard
                                    results={coletTopResultsBall(item?.ticketRows)}
                                    data={item}
                                    key={index}
                                    onCopy={(e)=>onCopy(e)}
                                />
                            )
                        })
                    }
                    <div className="more-ticket">
                        <button onClick={()=> getMoreTicket(stats?.data[stats?.data.length - 1]?.id)}>More Ticket</button>
                    </div>
                </div>
            case "HISTORY_EDITION":
                return <div className="history-edition">
                    {
                        stats?.data?.map((item,ind) => {
                            return <div className="ed-box" key={ind}>
                                <div className="edition-id">#{item.id}</div>
                                <ul>{item.data && drawEditionBalls(item.data)}</ul>
                            </div>
                        })
                    }
                </div>
            default:
                return loaderContent()
        }
    }

    const checkTab=()=>{
        if(data?.action.indexOf(hisTab.toUpperCase()) === -1 ){
            return loaderContent()
        }else {return true}
    }

    return (
        <>
            <ul className="history_tabs">
                <li onClick={()=>changeTab('my')} className={`${hisTab==='my'?'active':''}`}>My</li>
                <li onClick={()=>changeTab('edition')} className={`${hisTab==='edition'?'active':''}`}>Edition</li>
                <li onClick={()=>changeTab('stats')} className={`${hisTab==='stats'?'active':''}`}>Stats</li>
            </ul>
            <div className="section-title">
                <div>
                    <h4>{title[hisTab]}</h4>
                    {hisTab === 'stats' && <span>Number of hits in last 100 rounds</span>}
                </div>
                {hisTab === 'stats' && <button onClick={()=> setSortable(!sortable)}>{sortable?<img src={sort} alt="sort"/>:'123'}<img src={arrow} alt="sort"/></button>}

            </div>
            <div className="ticket-list">
                {
                    (hisTab && checkTab) && drawHistoryContent()
                }
            </div>
        </>
    )
}

export default History
