import {arrowDown, copyIcon} from "../../../assets/img/icon"
import React, {useEffect, useState} from "react"
import './TicketCard.scss'
const TicketCard=({results,data,onCopy})=> {

    /*const [res,setRes]= useState([])
    useEffect(()=>{
        setRes(results)
        console.log('results',results)
    },[results])*/

    return (
        <div className="ticket-card">
            <div className="bet-item">
                <ul>
                    <li className="user">
                        <i/>
                        <span>{data?.user?.username}</span>
                    </li>
                    <li className="bet">2.00</li>
                    <li className="win"><span>-</span></li>
                    <li className="action"><span>GEL</span><div className="arrow"><img src={arrowDown} alt="arrow" /></div></li>
                </ul>
            </div>
            <div className="bet-info">
                <div className="row-1">
                    <span>Classic</span>
                    <div className="actions">
                        <div className="copy" onClick={()=>onCopy(data?.balls)}><img src={copyIcon} alt="copy"/></div>
                        <div className="t_count"><span>{results.filter(num => data?.balls.includes(num)).length}</span> / {data?.balls.length}</div>
                    </div>
                </div>
                <ul className="row-2">
                    {
                        data?.balls && data?.balls.map((itm, ind) => {
                            return (
                                <li className={`${results.indexOf(itm) !== -1?'active':''}`} key={ind}>
                                    <div className={`ball`}>{itm}</div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default TicketCard
