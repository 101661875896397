import './notify.scss'
import {UseEvent} from "../../core/hooks/useEvent"
import {useEffect} from "react"
import PropTypes from "prop-types"

const Notify=({data})=> {
    const ev = UseEvent();

    useEffect(()=>{
        if(data?.delay && data?.delay !== null){
            setTimeout(close,data.delay)
        }
        return ()=>{

        }

    },[data])

    const close =()=> {
        ev.emit('notify', {
            show:false,
            text: '',
            type:'',
            title: '',
            delay: null
        })
    }

    return (
        <div id={`notify`}>
            <div className="notify-item" data-type={data?.type}>
                {data?.text}
            </div>
        </div>
    )
}

Notify.propTypes = {
    type: PropTypes.oneOf(['Success', 'Danger', 'Warning'])
}
export default Notify
