import React, {useEffect, useState} from "react"
import './resultsTab.scss'

const ResultsTab=({balls,onResult,recovery})=>{

    const [results, setResults] = useState(balls)
    const [showBalls, setShowBalls] = useState([])
    const [ind, setInd] = useState(0);
    const [ind2, setInd2] = useState(0);

    useEffect(()=>{
        setResults(balls)
    },[balls]);

    useEffect(()=>{
        if(recovery && recovery?.ballsToShow?.length>0){
            setShowBalls(recovery?.ballsToShow)
            setResults(recovery?.ballsToShow)
            setInd(recovery?.ballsToShow?.length)
        }
    },[recovery]);


    const drawBall=(i)=>{

        if(i <= 19){
            let arr = [
                ...showBalls,
                results[i]
            ]
            setShowBalls(arr)
            onResult(arr)

            setInd( ind => ind + 1);
        }
    }

    useEffect(()=>{
        const tm = setInterval(() => {
            if(results[ind] !== undefined){
                drawBall(ind)
            }else{
                setInd2( ind2 => ind2 + 1);
            }


        }, (15/20) * (1000-100));

        return () => {
            clearInterval(tm)
        };
    },[ind,ind2]);




    return (
        <>
            <div id="results">
                {
                    showBalls.map((itm,ind) => {

                        return <div key={ind} className={`ball result ${(itm) <= 40?'hot':'ice'}`}>
                            <div className="b">{itm}</div>
                        </div>

                    })
                }
            </div>
        </>
    )
}

export default ResultsTab
