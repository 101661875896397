import {hot, ice} from "../../assets/img/icon"
import React, {useEffect, useState} from "react"
import {SvgDot} from "../index"

const InfoTab=({editionId,i,h,onSelect})=>{

    const [iceNum,setIceNum] = useState([])
    const [hotNum,setHotNum] = useState([])

    useEffect(()=>{
        if(i && h){
            setIceNum(i)
            setHotNum(h)
        }
    },[i,h]);

    return (
        <>
            <div className="tour_id">
                <span>Edition ID:</span>
                {editionId? <span>{editionId}</span>: <div className="tour_loader"><SvgDot fill='#6cb0bb'/></div>}
            </div>
            <div className="hot_nums">
                <ul className="num-box hot">
                    <li className="ico"><img src={hot} alt="hot"/></li>
                    {
                        hotNum.length !== 0?(
                            hotNum.map((itm,ind) => {
                                return <li key={ind} onClick={()=>onSelect(itm)}>{itm}</li>
                            })
                        ):(
                            Array(5).fill('').map((itm, ind) => {
                                return <li key={ind}><i/></li>
                            })
                        )

                    }
                </ul>
                <ul className="num-box ice">
                    <li className="ico"><img src={ice} alt="hot"/></li>
                    {
                        iceNum.length !== 0?(
                            iceNum.map((itm,ind) => {
                                return <li key={ind} onClick={()=>onSelect(itm)}>{itm}</li>
                            })
                        ):(
                            Array(5).fill('').map((itm, ind) => {
                                return <li key={ind}><i/></li>
                            })
                        )
                    }
                </ul>
            </div>
            <div className="win_grid">
                <span className="icon-menu"/>
                <span>Payout Table</span>
            </div>
        </>
    )
}

export default InfoTab
