import './jackpotModal.scss'
import {star} from "../../assets/img/icon"

const JackpotModal=({jackpot,onClose})=>{
    return (
        <div className="jackpot-wrap">
            <img className="star star-left" src={star} alt="star"/>
            <img className="star star-center" src={star} alt="star"/>
            <img className="star star-right" src={star} alt="star"/>
            <div className="win">J A C K P O T</div>

            <div className="amount" data-text="YOU WON">
                <span>{jackpot[1]?.amount|| 50486}</span>
            </div>
            <div className="lines">
                <i className="large"/>
                <i className="small"/>
                <div>
                    <img className="star-bottom" src={star} alt="star"/>
                    <img className="star-bottom" src={star} alt="star"/>
                    <img className="star-bottom" src={star} alt="star"/>
                    <img className="star-bottom" src={star} alt="star"/>
                    <img className="star-bottom" src={star} alt="star"/>
                </div>
            </div>
            <div className="close" onClick={()=>onClose(false)}>C L O S E</div>
        </div>
    )
}
export default JackpotModal
