import React from "react"
import './AutoPlay.scss'
const AutoPlayModal=({autoPlayAction,autoPlay})=> {
    //console.log('autoPlay',autoPlay)

    return (
        <div className="autoPlayConfig">
            <div className="a_box">
                <div className="title">Chose Edition Count</div>
                <ul className="spinCount">
                    <li onClick={()=> autoPlayAction({type:'playCount',action:5})} className={autoPlay.playCount === 5? 'active':''}>5</li>
                    <li onClick={()=> autoPlayAction({type:'playCount',action:10})} className={autoPlay.playCount === 10? 'active':''}>10</li>
                    <li onClick={()=> autoPlayAction({type:'playCount',action:15})} className={autoPlay.playCount === 15? 'active':''}>15</li>
                    <li onClick={()=> autoPlayAction({type:'playCount',action:20})} className={autoPlay.playCount === 20? 'active':''}>20</li>
                    <li onClick={()=> autoPlayAction({type:'playCount',action:30})} className={autoPlay.playCount === 30? 'active':''}>30</li>
                    <li onClick={()=> autoPlayAction({type:'playCount',action:50})} className={autoPlay.playCount === 50? 'active':''}>50</li>
                    <li onClick={()=> autoPlayAction({type:'playCount',action:70})} className={autoPlay.playCount === 70? 'active':''}>70</li>
                    <li onClick={()=> autoPlayAction({type:'playCount',action:100})} className={autoPlay.playCount === 100? 'active':''}>100</li>
                    <li onClick={()=> autoPlayAction({type:'playCount',action:150})} className={autoPlay.playCount === 150? 'active':''}>150</li>
                    <li onClick={()=> autoPlayAction({type:'playCount',action:200})} className={autoPlay.playCount === 200? 'active':''}>200</li>
                </ul>
            </div>
            <div className="a_box">
                <div className="title">Chose Random Bet</div>
                <ul className="randomBet">
                    <li onClick={()=> autoPlayAction({type:'random',action:1})} className={autoPlay.random === 1? 'active':''}>1</li>
                    <li onClick={()=> autoPlayAction({type:'random',action:2})} className={autoPlay.random === 2? 'active':''}>2</li>
                    <li onClick={()=> autoPlayAction({type:'random',action:3})} className={autoPlay.random === 3? 'active':''}>3</li>
                    <li onClick={()=> autoPlayAction({type:'random',action:4})} className={autoPlay.random === 4? 'active':''}>4</li>
                    <li onClick={()=> autoPlayAction({type:'random',action:5})} className={autoPlay.random === 5? 'active':''}>5</li>
                    <li onClick={()=> autoPlayAction({type:'random',action:6})} className={autoPlay.random === 6? 'active':''}>6</li>
                    <li onClick={()=> autoPlayAction({type:'random',action:7})} className={autoPlay.random === 7? 'active':''}>7</li>
                    <li onClick={()=> autoPlayAction({type:'random',action:8})} className={autoPlay.random === 8? 'active':''}>8</li>
                    <li onClick={()=> autoPlayAction({type:'random',action:9})} className={autoPlay.random === 9? 'active':''}>9</li>
                    <li onClick={()=> autoPlayAction({type:'random',action:10})} className={autoPlay.random === 10? 'active':''}>10</li>
                </ul>
            </div>
            <div className="btn">
                <button onClick={()=>autoPlayAction({type:'startAutoPlay'})}>Auto Play</button>
            </div>
        </div>
    )
}

export default AutoPlayModal
