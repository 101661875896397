import React, {useEffect, useState} from "react"
import './Ticket.scss'
import TicketCard from "../ticketCard/TicketCard"
import {reload, ticket, close} from "../../../assets/img/icon"
import _ from "lodash"
import {coletTopResultsBall} from "../../../core/utils/helper"

const Ticket=({ticketList,results,onCopy,prevTickets,getAction})=>{

    const [prev,setPrev] = useState(false)
    //console.log('ticketList',ticketList)
    useEffect(()=>{
        if(prev){
            getAction('previous',{action:'previous',extra:null})
        }
    },[prev])

    return (
        <>
            <div className="ticket-actions">
                <span className="count"><img src={ticket} alt="ticket"/> <span>{_.size(prev?prevTickets?.tickets:ticketList)}</span> </span>
                <div className="prev">
                    {prev && <div className="tour_id"><span>Last #:</span><span>{prevTickets?.previousEditionId}</span></div>}
                    <button onClick={()=> setPrev(!prev)} className={prev?'active':''}><img src={prev? close :reload} alt="ticket"/>Previous Hand</button>
                </div>
            </div>
            <div className="ticket-list">
                {
                    //Array(40).fill(0)
                    //ticketList.map((item, index) => {
                    prev?(
                        prevTickets?.tickets?.length > 0? prevTickets?.tickets.map((itm,ind)=>{
                            return (
                                <TicketCard
                                    results={coletTopResultsBall(itm?.ticketRows)}
                                    data={itm}
                                    key={ind}
                                    onCopy={(e)=>onCopy(e)}
                                />
                            )
                        }):<div className="empty_tickets">No Bets Found</div>
                    ):(
                        Object.keys(ticketList).map((key, index) => {
                            return (
                                <TicketCard
                                    results={results}
                                    data={ticketList[key]}
                                    key={index}
                                    onCopy={(e)=>onCopy(e)}
                                />
                            )
                        })
                    )

                }

            </div>
        </>
    )
}

export default Ticket
