import SockJS from 'sockjs-client';
import { over } from 'stompjs'
//import JWT from "../models/JWT";
import {useEffect, useRef, useState} from "react";
import axios from "axios"

let stompClient;
export function useSocket(){
    //let interval = ''

    let socket = null;
    const [connected,setConnected] = useState(false)
    const [user,setUser]=useState({})
    const connect=()=> {
        try{
            if(socket){
                socket.close()
            }
            socket=new SockJS('/ws')
            stompClient = over(socket);
            stompClient.debug = null
            stompClient.connect(
                //{'X-Authorization': `bearer ${jwt.access}`,
                //    'Secure':true
                //},
                {},
                (e) => {
                    console.log("con-1",e,stompClient)
                    setConnected(true);
                },
                (err)=>{
                    console.log("err-1 ", err)
                    setConnected(false);

                    initial()
                }
            )
        }catch(e) {
            console.log("errr",e)
        }
    }
    const initial=()=>{
        axios.get('/api/ping')
            .then(response => {
                if(response?.data?.hash){
                    setUser(response?.data)
                }else{
                    window.top.location.href = '/login'
                    return;
                }
                connect()
                setUser(response?.data)
            })
            .catch(error => {
                // Handle any errors that occurred
                setTimeout(()=>{
                    initial()
                },1000)
            });
    }
    const subscribe=(endpoint,callback)=>{
        return stompClient.subscribe(endpoint,callback)
    }
    const send=(endpoint,data)=>{
        return stompClient.send(endpoint, {}, JSON.stringify(data))
    }

    return {subscribe,connected,send,connect,user,initial}
}



