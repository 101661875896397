import React, {useEffect, useState} from "react"
import './Top.scss'
import TicketCard from "../ticketCard/TicketCard"
import {coletTopResultsBall} from "../../../core/utils/helper"

const Top=({data,getAction,onCopy})=>{
    const [topTab,setTopTab] = useState('')
    const [top,setTop]=useState({})

    useEffect(()=>{
        setTop({})
        if(topTab !== ''){
            getAction('top',topTab)
        }
    },[topTab])

    useEffect(()=>{
        setTop(data)
    },[data])

    useEffect(()=>{
        setTopTab('day')
    },[])

    const loaderContent=()=> {
        return <div className="top-loader">
            <div className="loader">
                <div className="inner one"/>
                <div className="inner two"/>
                <div className="inner three"/>
            </div>
        </div>
    }

    const checkTab=()=>{
        if(top?.action.indexOf(topTab.toUpperCase()) === -1 ){
            return loaderContent()
        }else {return true}
    }

    return (
        <>
            <ul className="top_tabs">
                <li onClick={()=>setTopTab('day')} className={`${topTab==='day'?'active':''}`}>Day</li>
                <li onClick={()=>setTopTab('month')} className={`${topTab==='month'?'active':''}`}>Month</li>
                <li onClick={()=>setTopTab('year')} className={`${topTab==='year'?'active':''}`}>Year</li>
            </ul>
            <div className="ticket-list">
                {
                    checkTab && top?.data ? Object.keys(top?.data).map((key, index) => {
                        return (
                            <TicketCard
                                results={coletTopResultsBall(top?.data[key]?.ticketRows)}
                                data={top?.data[key]}
                                key={index}
                                onCopy={(e)=>onCopy(e)}
                            />
                        )
                    }):loaderContent()
                }
            </div>
        </>
    )
}

export default Top
