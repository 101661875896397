import React, {useEffect, useState} from "react"
import './assets/scss/style.scss';
import {useSocket} from "./core/hooks/useSocket";
import KenoApp from "./KenoApp"
import {UseEvent} from "./core/hooks/useEvent"
import {Notify} from "./components"
import axios from "axios"

function App() {
    const socket = useSocket();
    const ev = UseEvent();

    const [notify,setNotify]=useState({
        show: false,
        text:'',
        type:'',
        title:''
    });



    useEffect(()=>{
        const showNotify= ev.subscribe("notify",setNotify)
        socket.initial()
        return ()=>{
            showNotify.unsubscribe()
        }
    },[])


    useEffect(()=>{
        ev.emit('notify', {
            show: !socket.connected,
            text: 'Lost Connection',
            type:'Danger',
            title: '',
            delay: null
        })
    },[socket.connected])

    return (
        <>
            <KenoApp socket={socket} user={socket.user}/>
            {notify.show && <Notify data={notify}/>}
        </>
    );
}

export default App;
