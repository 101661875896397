import './Header.scss'
import React, {useEffect, useState} from "react"
import {coin, logo, logOut} from "../../assets/img/icon"
import {SvgDot} from "../index"
const Header =({jackpot})=>{
    const [jack, setJack] = useState([])
    const [minor, setMinor] = useState(0)
    const [major, setMajor] = useState(0)
    const [balance, setBalance] = useState(54.34)

    useEffect(()=>{
        //console.log(jackpot)
        if(jackpot?.length > 0 ){
            jackpot.map((itm,ind)=>{
                if(itm?.name === "Minor"){
                    setMinor(itm?.amount)
                }
                if(itm?.name === "Major"){
                    setMajor(itm?.amount)
                }
            })
        }
    },[jackpot])

    const loader =()=>{
        return <div className="jack_loader"><SvgDot fill='#6cb0bb'/></div>
    }

    return (
        <div className="header">
            <div className="logo"><img src={logo} alt="logo"/></div>
            {
                <div className="jackpots">
                    <span>JACKPOT</span>
                    <div className="j_wrap">
                        <div className="j_box">
                            <div className="title">MINOR</div>
                            <div className="jackpot">{minor !== 0?minor: loader()}</div>
                        </div>
                        <div className="j_box">
                            <div className="title">MAJOR</div>
                            <div className="jackpot">{major !== 0?major: loader()}</div>
                        </div>
                        {/*{
                            jack.map((itm,ind)=> {
                                return <div className="j_box">
                                    <div className="title">{itm?.name}</div>
                                    <div className="jackpot">{itm?.amount}</div>
                                </div>
                            })
                        }*/}
                    </div>

                </div>
            }
            {/*<div className="balance">
                <img src={coin} alt="balance"/>
                <span>{balance}</span>
            </div>*/}
            <div className="log_out">
                <span>Log Out</span>
                <img src={logOut} alt="LogOut"/>
            </div>

        </div>
    )
}

export default Header
